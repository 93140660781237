import { gsap, Expo, CSSPlugin } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollToPlugin);

/**
 * Opens an element
 *
 * @example
 * import {open} from '../helpers';
 *
 * open({ element: this.el.querySelector('.element)});
 *
 * @param {Object} element - HTML element you want to target for animation
 * @param {Number} duration - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */
export const open = ({
  element,
  duration = 0.4,
  ease = 'easeOut',
  onComplete
}) => {
  gsap.set(element, { height: 'auto' });
  gsap.from(element, {
    height: 0,
    ease: Expo[ease],
    duration,
    onComplete: () => {
      onComplete && onComplete();
    }
  });
};

/**
 * Closes an element
 *
 * @example
 * import {close} from '../helpers';
 *
 * close({ element: this.el.querySelector('.element)});
 *
 * @param {Object} element - HTML element you want to target for animation
 * @param {Number} duration - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */
export const close = ({
  element,
  duration = 0.4,
  ease = 'easeOut',
  onComplete
}) => {
  gsap.set(element, { height: element.offsetHeight });
  gsap.to(element, {
    height: 0,
    ease: Expo[ease],
    duration,
    onComplete: () => {
      onComplete && onComplete();
    }
  });
};

// /**
// * Scrolls to a certain part of the page with GSAP
// *
// * @param {Number} y - The new y position
// * @param {Number} speed - The spped in which the screen scrolls
// * @param {Function} onComplete - On complete function
// */
export const scrollTo = (y, duration = 1.5, onComplete = () => {}) => {
  gsap.to(window, {
    scrollTo: { y },
    duration,
    ease: Expo.easeOut,
    onComplete
  });
};

// /**
// * Checks if an element is focusable
// *
// * @param {Object} el - HTML element you want to check if it's focusable
// */
export const isFocusable = el => {
  if (
    el.tabIndex > 0 ||
    (el.tabIndex === 0 && el.getAttribute('tabIndex') !== null)
  ) {
    return true;
  }

  if (el.disabled) {
    return false;
  }

  switch (el.nodeName) {
    case 'A':
      return !!el.href && el.rel !== 'ignore';
    case 'INPUT':
      return el.type !== 'hidden' && el.type !== 'file';
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true;
    default:
      return false;
  }
};

// /**
// * Checks browser is running on a native mobile device
// */
export const isDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  ) {
    return true;
  }

  return false;
};

// /**
// * Debounce function
// * Delays the processing of the event
// */
export const debounce = (callback, wait) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

// /**
// * Key code list object
// */
export const keyCode = {
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};

// /**
// * Camelize function
// * Converts a string into camelcase
// */
export const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
