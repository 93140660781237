import { Component } from '@verndale/core';
import { isFocusable, keyCode } from '../helpers';

class Module extends Component {
  setupDefaults() {
    this.ignoreUtilFocusChanges = false;
    this.lastFocus = null;

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.dom = {
      body: document.querySelector('body'),
      modal: document.getElementById(this.el.dataset.modal)
    };

    this.dom.close = this.dom.modal.querySelector('[data-modal-js="modal__close"]');
    this.dom.box = this.dom.modal.querySelector('[data-modal-js="modal__box"]');
  }

  addListeners() {
    this.el.addEventListener('click', this.handleModalOpen.bind(this));
    this.dom.close.addEventListener('click', this.handleModalClose.bind(this));
  }

  addEventListeners() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('keyup', this.handleEscape);
    document.addEventListener('focus', this.handleFocus, true);
  }

  removeEventListeners() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keyup', this.handleEscape);
    document.removeEventListener('focus', this.handleFocus, true);
  }

  handleModalOpen() {
    const scrollY = window.scrollY;
    this.dom.body.style.top = `-${scrollY}px`;
    this.dom.body.classList.add('has-modal');
    this.dom.modal.classList.add('modal--open');

    this.addEventListeners();

    setTimeout(() => this.focusFirstDescendant(this.dom.modal), 300);
  }

  handleModalClose() {
    const scrollY = document.body.style.top;
    this.dom.body.style.top = '';
    this.dom.body.classList.remove('has-modal');
    this.dom.modal.classList.remove('modal--open');
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    this.removeEventListeners();
    this.el.focus();
  }

  handleClickOutside(e) {
    if (e.target === this.dom.box || this.dom.box.contains(e.target)) {
      return;
    }

    this.handleModalClose();
  }

  handleEscape(e) {
    const key = e.which || e.keyCode;

    if (key === keyCode.ESC) {
      e.stopPropagation();
      this.handleModalClose();
    }
  }

  handleFocus(e) {
    if (this.ignoreUtilFocusChanges) return;

    if (this.dom.modal.contains(e.target)) {
      this.lastFocus = e.target;
    } else {
      this.focusFirstDescendant(this.dom.modal);

      if (this.lastFocus === document.activeElement) {
        this.focusLastDescendant(this.dom.modal);
      }
      this.lastFocus = document.activeElement;
    }
  }

  focusFirstDescendant(element) {
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i];
      if (this.attemptFocus(child) || this.focusFirstDescendant(child)) {
        return true;
      }
    }
    return false;
  }

  focusLastDescendant(element) {
    for (let i = element.childNodes.length - 1; i >= 0; i--) {
      const child = element.childNodes[i];
      if (this.attemptFocus(child) || this.focusLastDescendant(child)) {
        return true;
      }
    }
    return false;
  }

  attemptFocus(element) {
    if (!isFocusable(element)) {
      return false;
    }

    this.ignoreUtilFocusChanges = true;
    try {
      element.focus();
    } catch (e) {
      return;
    }
    this.ignoreUtilFocusChanges = false;
    return document.activeElement === element;
  }
}

export default Module;
