import 'focus-visible';
import create from '@verndale/core';
import modules from './modules';
import Modal from './modules/modal';

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  // eslint-disable-next-line object-shorthand
  get: function () {
    return !!(
      this.currentTime > 0 &&
      !this.paused &&
      !this.ended &&
      this.readyState > 2
    );
  }
});

document.addEventListener('DOMContentLoaded', () => {
  create(modules);

  [...document.querySelectorAll('[data-modal]')].forEach(el => new Modal(el));
});
