const reactModule = (Component, nodeList) => {
  const React = require('react');
  const { render } = require('react-dom');

  nodeList.forEach(node => render(<Component {...node.dataset} />, node));
};

export default [
  {
    name: 'backToTop',
    loader: () => import('./components/backToTop')
  },
  {
    name: 'scurveCard',
    loader: () => import('./components/scurveCard')
  },
  {
    name: 'spotlightCardSlider',
    loader: () => import('./components/spotlightCardSlider')
  },
  {
    name: 'testimonialBanner',
    loader: () => import('./components/testimonialBanner')
  },
  {
    name: 'videoCard',
    loader: () => import('./components/videoCard')
  },
  {
    name: 'alertBanner',
    loader: () => import('./components/alertBanner')
  },
  {
    name: 'dropdown',
    loader: () => import('./components/dropdown')
  },
  {
    name: 'search',
    loader: () => import('./components/search')
  },
  {
    name: 'formFields',
    loader: () => import('./components/formFields')
  },
  {
    name: 'tableOfContentsSummary',
    loader: () => import('./modules/tableOfContentsSummary')
  },
  {
    name: 'header',
    loader: () => import('./modules/header/index')
  },
  {
    name: 'irSubNavigation',
    loader: () => import('./modules/irSubNavigation')
  },
  {
    name: 'heroSpotlight',
    loader: () => import('./modules/heroSpotlight')
  },
  {
    name: 'videoModal',
    loader: () => import('./modules/videoModal')
  },
  {
    name: 'verticalCardCarousel',
    loader: () => import('./modules/verticalCardCarousel')
  },
  {
    name: 'noteworthyContentCarousel',
    loader: () => import('./modules/noteworthyContentCarousel')
  },
  {
    name: 'threecardsacrossCareerslisting',
    loader: () => import('./modules/threecardsacrossCareerslisting')
  },
  {
    name: 'clientPortfolio',
    loader: () => import('./modules/clientPortfolio')
  },
  {
    name: 'epiForm',
    loader: () => import('./modules/epiForm')
  },
  {
    name: 'genericContent',
    loader: () => import('./modules/genericContent')
  },
  {
    name: 'columCardListing',
    loader: () => import('./modules/columCardListing')
  },
  {
    name: 'solutionsGrid',
    loader: () => import('./modules/solutionsGrid')
  },
  {
    name: 'contentTabs',
    loader: () => import('./modules/contentTabs')
  },
  {
    name: 'tabModuleContainer',
    loader: () => import('./modules/tabModuleContainer')
  },
  {
    name: 'solutionIntegratedOfferingsTab',
    loader: () => import('./modules/solutionIntegratedOfferingsTab')
  },
  {
    name: 'solutionFeatured',
    loader: () => import('./modules/solutionFeatured')
  },
  {
    name: 'awardsTabModuleContainer',
    loader: () => import('./modules/awardsTabModuleContainer')
  },
  {
    name: 'socialMediaHighlights',
    loader: () => import('./modules/socialMediaHighlights')
  },
  {
    name: 'locationsListing',
    loader: () => import('./modules/locationsListing'),
    render: reactModule
  },
  {
    name: 'partnerCardListingNew',
    loader: () => import('./modules/partnerCardListingNew'),
    render: reactModule
  },
  {
    name: 'insightsCenterCardListing',
    loader: () => import('./modules/insightsCenterCardListing'),
    render: reactModule
  },
  {
    name: 'awardsListing',
    loader: () => import('./modules/awardsListing'),
    render: reactModule
  },
  {
    name: 'contactUsForm',
    loader: () => import('./modules/contactUsForm')
  },
  {
    name: 'videoCarousel',
    loader: () => import('./modules/videoCarousel')
  },
  {
    name: 'blockquote',
    loader: () => import('./modules/blockquote')
  },
  {
    name: 'accordion',
    loader: () => import('./modules/accordion')
  },
  {
    name: 'historyCarousel',
    loader: () => import('./modules/historyCarousel')
  },
  {
    name: 'tabFacetedListing',
    loader: () => import('./modules/tabFacetedListing'),
    render: reactModule
  },
  {
    name: 'mediaCarousel',
    loader: () => import('./modules/mediaCarousel')
  },
  {
    name: 'featuredcardCarouselmodule',
    loader: () => import('./modules/featuredcardCarouselmodule')
  },
  {
    name: 'glossaryList',
    loader: () => import('./modules/glossaryList')
  },
  {
    name: 'dualCtaHero',
    loader: () => import('./modules/dualCtaHero')
  },
  {
    name: 'subnavigation',
    loader: () => import('./modules/subnavigation')
  },
  {
    name: 'jumpLinkList',
    loader: () => import('./modules/jumpLinkList')
  },
  {
    name: 'earnings-release-table',
    loader: () => import('./modules/earningsReleaseTable')
  },
  {
    name: 'partner-card-listing',
    loader: () => import('./modules/partnerCardListing')
  },
  {
    name: 'audioPlayer',
    loader: () => import('./modules/audioPlayer/')
  },
  {
    name: 'subscribeCard',
    loader: () => import('./modules/subscribeCard')
  },
  {
    name: 'pageForms',
    loader: () => import('./modules/pageForms')
  },
  {
    name: 'solutions',
    loader: () => import('./modules/solutions')
  },
  {
    name: 'multiMediaCarousel',
    loader: () => import('./modules/multiMediaCarousel')
  },
  {
    name: 'homepageBloomHero',
    loader: () => import('./modules/homepageBloomHero')
  },
  {
    name: 'cardListing',
    loader: () => import('./components/react/CardListing'),
    render: reactModule
  },
  {
    name: 'breadcrumbs',
    loader: () => import('./components/breadcrumbs')
  }
];
